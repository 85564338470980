import { removeNonNumericsExceptDash } from 'components/CurrencyInput/utils';
import { isBefore, isValid, parse, isAfter, isToday } from 'date-fns';
import {
  isValidCPF,
  isValidCNPJ,
  isValidMobilePhone,
} from '@brazilian-utils/brazilian-utils';
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { TerminalCancelSerialsResponse } from 'types/pos';

type YupStringSchema = yup.StringSchema;

/**
 * Checa se um valor é nulo ou indefinido.
 */
const isNullOrUndefined = function (
  value: string | number | undefined,
): boolean {
  return value === null || value === undefined;
};

/** Validator para valor monetário. */
yup.addMethod(yup.string, 'totalValue', function (errorMessage) {
  return this.test('total-value', errorMessage, function (value) {
    const { path, createError } = this;
    const valueFormatted = removeNonNumericsExceptDash(value || '');
    return (
      (valueFormatted && Number(valueFormatted) >= 500) ||
      createError({ path, message: errorMessage })
    );
  });
});

/** Validator para valores do cupom */
yup.addMethod(yup.string, 'couponValue', function (errorMessage) {
  return this.test('coupon-value', errorMessage, function (value) {
    const { path, createError } = this;
    const valueFormatted = removeNonNumericsExceptDash(value || '');
    return (
      (valueFormatted && Number(valueFormatted) > 0) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(
  yup.string,
  'couponUtilizationQuantity',
  function (utilizationQuantity, errorMessage) {
    return this.test('utilization-quantity', errorMessage, function (value) {
      const { path, createError } = this;
      return (
        (value && Number(value) - utilizationQuantity > 0) ||
        createError({ path, message: errorMessage })
      );
    });
  },
);

/** Validator para data no formato pt-BR, não incluindo a data de hoje */
yup.addMethod(yup.string, 'brazilianDate', function (errorMessage) {
  return this.test('brazilian-date', errorMessage, function (value) {
    const { path, createError } = this;
    const date = parse(value as string, 'dd/MM/yyyy', new Date());

    return (
      (isValid(date) && !isBefore(date, new Date())) ||
      createError({ path, message: errorMessage })
    );
  });
});
/** Validador para data no formato pt-BR, incluindo a data de hoje */
yup.addMethod(yup.string, 'brazilianDateToday', function (errorMessage) {
  return this.test('brazilian-date-today', errorMessage, function (value) {
    const { path, createError } = this;
    const date = parse(value as string, 'dd/MM/yyyy', new Date());
    return (
      (isValid(date) && isAfter(date, new Date())) ||
      isToday(date) ||
      createError({ path, message: errorMessage })
    );
  });
});

/** Validator para data de aniversário no formato pt-BR. */
yup.addMethod(yup.string, 'birthDate', function (errorMessage) {
  return this.test('birth-date', errorMessage, function (value) {
    const { path, createError } = this;
    const date = parse(value as string, 'dd/MM/yyyy', new Date());

    return (
      (isValid(date) && isBefore(date, new Date())) ||
      createError({ path, message: errorMessage })
    );
  });
});

/** Validator para verificar tamanho correto de CEP. */
yup.addMethod(yup.string, 'zipCodeSize', function (errorMessage) {
  return this.test('zipcode-size', errorMessage, function (value) {
    const { path, createError } = this;
    const zipCode = (value as string).match(/\d+/g)?.join('').length === 8;

    return zipCode || createError({ path, message: errorMessage });
  });
});

/** Validator para formato apenas número ou 'SN'. */
yup.addMethod(yup.string, 'addressNumber', function (errorMessage) {
  return this.test('address-number', errorMessage, function (value) {
    const { path, createError } = this;
    const number =
      !isNaN(Number(value as string)) ||
      (isNaN(Number(value as string)) && (value as string) === 'SN');

    return number || createError({ path, message: errorMessage });
  });
});

/** Validator para permitir apenas seriais sem cancelamento solicitado. */
yup.addMethod(
  yup.string,
  'availableSerial',
  function (serialData: TerminalCancelSerialsResponse[], errorMessage) {
    return this.test('available-serial', errorMessage, function (value) {
      const { path, createError } = this;
      const availableSerial = serialData.some(
        terminal =>
          terminal.serialNumber === value &&
          terminal.terminalHasCancelRequest === true,
      );

      return !availableSerial || createError({ path, message: errorMessage });
    });
  },
);

/** Validator para formato válido do serial de maquininha. */
yup.addMethod(yup.string, 'serialValue', function (errorMessage) {
  return this.test('serial-value', errorMessage, function (value) {
    const { path, createError } = this;
    const untaggedSerial = (value as string).replace('S/N: ', '');
    const eightDigitsRegex = /^6[A-Za-z]\d{6}$/;
    const elevenDigitsRegex = /^\d{3}-\d{3}-\d{3}$/;
    const isSerialMatching =
      (untaggedSerial.length === 8 &&
        untaggedSerial.match(eightDigitsRegex) !== null) ||
      (untaggedSerial.length === 11 &&
        untaggedSerial.match(elevenDigitsRegex) !== null);

    return isSerialMatching || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'brazilianPhone', function (errorMessage) {
  return this.test('brazilian-phone', errorMessage, function (value) {
    const { path, createError } = this;
    const phone = isValidMobilePhone(value as string);
    return phone || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'cpf', function (errorMessage) {
  return this.test('brazilian-cpf', errorMessage, function (value) {
    const { path, createError } = this;
    const phone = isValidCPF(value as string);
    return phone || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'cpfOrCnpj', function (errorMessage) {
  return this.test('brazilian-cpf-cnpj', errorMessage, function (value) {
    const { path, createError } = this;
    const phone =
      value && value.length > 14
        ? isValidCNPJ(value as string)
        : isValidCPF(value as string);
    const message =
      value && value.length > 14 ? 'CNPJ inválido' : 'CPF inválido';
    return phone || createError({ path, message: message });
  });
});

/**
 * Valida uma string se contem algum simbolo especial.
 * @param length - Tamanho mínimo da string.
 * @param message - Mensagem de erro.
 */
function minSymbols(this: YupStringSchema, length = 1, message: string) {
  return this.test({
    name: 'minSymbol',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return (
        isNullOrUndefined(value) ||
        ((value as string).match(/[^a-zA-Z0-9\s]/g) || []).length >= length
      );
    },
  });
}

/**
 * Valida uma string se contem algum número.
 * @param length - Tamanho mínimo da string.
 * @param message - Mensagem de erro.
 */
function minNumbers(this: YupStringSchema, length = 1, message: string) {
  return this.test({
    name: 'minNumber',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return (
        isNullOrUndefined(value) ||
        ((value as string).match(/[0-9]/g) || []).length >= length
      );
    },
  });
}

/**
 * Valida uma string se contem alguma palavra minuscula.
 * @param length - Tamanho mínimo da string.
 * @param message - Mensagem de erro.
 */
function minLowercase(this: YupStringSchema, length = 1, message: string) {
  return this.test({
    name: 'minLowercase',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return (
        isNullOrUndefined(value) ||
        ((value as string).match(/[a-z]/g) || []).length >= length
      );
    },
  });
}

/**
 * Valida uma string se contem alguma palavra maiuscula.
 * @param length - Tamanho mínimo da string.
 * @param message - Mensagem de erro.
 */
function minUppercase(this: YupStringSchema, length = 1, message: string) {
  return this.test({
    name: 'minUppercase',
    exclusive: true,
    message,
    params: { length },
    test(value) {
      return (
        isNullOrUndefined(value) ||
        ((value as string).match(/[A-Z]/g) || []).length >= length
      );
    },
  });
}

yup.addMethod(yup.string, 'minSymbols', minSymbols);
yup.addMethod(yup.string, 'minNumbers', minNumbers);
yup.addMethod(yup.string, 'minUppercase', minUppercase);
yup.addMethod(yup.string, 'minLowercase', minLowercase);

/** Type definitions. */
declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    brazilianDate(message?: string): StringSchema<TType, TContext>;
    birthDate(message?: string): StringSchema<TType, TContext>;
    brazilianDateToday(message?: string): StringSchema<TType, TContext>;

    zipCodeSize(message?: string): StringSchema<TType, TContext>;

    addressNumber(message?: string): StringSchema<TType, TContext>;

    availableSerial(
      serialData: TerminalCancelSerialsResponse[],
      message?: string,
    ): StringSchema<TType, TContext>;

    serialValue(message?: string): StringSchema<TType, TContext>;

    totalValue(message?: string): StringSchema<TType, TContext>;

    couponValue(message?: string): StringSchema<TType, TContext>;

    couponUtilizationQuantity(
      utilizationQuantity: number,
      message?: string,
    ): StringSchema<TType, TContext>;

    brazilianPhone(message?: string): StringSchema<TType, TContext>;

    cpf(message?: string): StringSchema<TType, TContext>;

    cpfOrCnpj(message?: string): StringSchema<TType, TContext>;

    minSymbols(length?: number, message?: string): StringSchema;
    minLowercase(length?: number, message?: string): StringSchema;
    minUppercase(length?: number, message?: string): StringSchema;
    minNumbers(length?: number, message?: string): StringSchema;
  }
}

export default yup;
